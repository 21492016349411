import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [
        // {
        //     path: "/",
        //     props: true,
        //     component: () =>
        //         import ("./views/Jobs/job"),
        //     meta: {
        //         requiresAuth: false,
        //     },
        // },
        // {
        //     path: "/JobDetails",
        //     name: "JobDetails",
        //     props: true,
        //     component: () =>
        //         import ("./views/JobDetails/jobDetails"),
        //     meta: {
        //         requiresAuth: false,
        //     },
        // },
        // {
        //     path: "/volunteerDetails",
        //     name: "volunteerDetails",
        //     props: true,
        //     component: () =>
        //         import ("./volunteer/volunteerDetails/jobDetails"),
        //     meta: {
        //         requiresAuth: false,
        //     },
        // },
        // {
        //     path: "/volunteer",
        //     name: "volunteer",
        //     props: true,
        //     component: () =>
        //         import ("./volunteer/job"),
        //     meta: {
        //         requiresAuth: false,
        //     },
        // },
        {
            path: "/",
            name: "Shopping",
            meta: {
                requiresAuth: false,
                isClient: true,
            },
            component: () =>
                import ("./views/Shopping/shopping"),
            children: [
                // {
                //     path: "",
                //     name: "ShopHome",
                //     component: () =>
                //         import ("./views/Shopping/Home/shopHome"),
                //     meta: {
                //         requiresAuth: false,
                //     },
                // },
                // {
                //     path: "/Products",
                //     name: "Products",
                //     component: () =>
                //         import ("./views/Shopping/products"),
                //     meta: {
                //         requiresAuth: false,
                //     },
                // },
                // product/:id
                {
                    path: "/",
                    props: true,
                    name: "Product",
                    component: () =>
                        import ("./views/Shopping/product"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/AllVisitors",
                    name: "AllVisitors",
                    component: () =>
                        import ("./views/Shopping/Home/allCustomerReviews"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/AllReviews/:prod",
                    props: true,
                    name: "AllReviews",
                    component: () =>
                        import ("./views/Shopping/allProdReviews"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/similarProducts",
                    name: "similarProducts",
                    component: () =>
                        import ("./views/Shopping/similarProducts"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/productDetails",
                    props: true,
                    name: "ProductsDetails",
                    component: () =>
                        import ("./views/Shopping/productDetails"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/productCart",
                    props: true,
                    name: "ProductsCart",
                    component: () =>
                        import ("./views/Shopping/Cart/productCart"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/shipDetails",
                    props: true,
                    name: "shipDetails",
                    component: () =>
                        import ("./views/Shopping/Cart/shipDetails"),
                    meta: {
                        requiresAuth: true,
                    },
                },

                {
                    path: "Feedback",
                    name: "Feedback",
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ("./views/Shopping/feedback"),
                },
                {
                    path: "orders",
                    props: true,
                    name: "orders",
                    component: () =>
                        import ("./views/Shopping/Orders/myOrders"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                // {
                //   path: "PaywaitScreenC/:paymentId/:orderid",
                //   name: "PaywaitScreenC",
                //   meta: {
                //     requiresAuth: true,
                //   },
                //   props: true,
                //   component: () =>
                //     import("./views/Shopping/Cart/Payment/PaywaitScreen"),
                // },
                {
                    path: "PaywaitScreen/:paymentId/:orderid/:productId",
                    name: "PaywaitScreen",
                    meta: {
                        requiresAuth: true,
                    },
                    props: true,
                    component: () =>
                        import ("./views/Shopping/Payment/PaywaitScreen"),
                },

                {
                    path: "/paymentSuccess/:paymentId",
                    name: "PaywaitScreenSuccess",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Shopping/Payment/Success"),
                },
                {
                    path: "/PaymentFailure",
                    name: "PaywaitScreenFailure",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Shopping/Payment/Failure"),
                },

                {
                    path: "/Wait",
                    name: "Wait",
                    meta: {
                        requiresAuth: true,
                    },
                    props: true,
                    component: () =>
                        import (
                            "./views/Shopping/Payment/Wait"
                        ),
                },

                {
                    path: "/profile",
                    name: "profile",
                    component: () =>
                        import ("./views/Profile/profileInfo"),
                    meta: {
                        requiresAuth: true,
                    },
                },
            ],
        },

        {
            path: "/",
            props: true,
            component: () =>
                import ("./components/Common/Authentication"),
            meta: {
                requiresAuth: false,
            },
            children: [{
                    path: "login",
                    name: "login",
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ("./components/Common/appLogin"),
                    // component: () => import("./views/Authentication/adminLogin"),
                },
                {
                    path: "signup",
                    name: "signup",
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ("./views/Authentication/Signup.vue"),
                },
            ],
        },

        {
            path: "/ServerError",
            name: "ServerError",
            props: true,
            component: () =>
                import ("./components/Common/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "*",
            name: "404PageNotFound",
            props: true,
            component: () =>
                import ("./components/Common/404"),
            meta: {
                requiresAuth: false,
            },
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
});

router.beforeEach((to, from, next) => {
    if (to.name !== "login") {
        localStorage.setItem("routeKey", to.fullPath);
        localStorage.setItem("routeName", to.name);
        localStorage.removeItem("routeNamelogin");
        store.commit("rvar", to.name);
    }
    if (to.name == "login") {
        localStorage.setItem("routeNamelogin", to.name);
    }
    if (
        to.matched.some((route) => route.name == "login") &&
        store.state.isLoggedIn
    ) {
        next({ name: "Product" });
        return;
    }
    if (
        store.state.isLoggedIn == true ||
        to.matched.some((route) => route.meta.requiresAuth == true)
    ) {
        axios({
                method: "POST",
                url: "/user/me/product",
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
            .then((response) => {
                if (!response.data.status) {
                    store.commit("sessionOut", true);
                    return;
                }
            })
            .catch((err) => {
                var msg = err;
                console.log(msg);
            });
    }
    // if (to.name == "ProductsCart" && store.state.isLoggedIn == false) {
    //   next({ path: "/login" });
    //   return;
    // }
    if (
        to.name == "login" &&
        store.state.isLoggedIn == true &&
        store.state.userType == "customer"
    ) {
        next({ path: "/Shopping" });
        return;
    }

    // if (
    //   to.matched.some((route) => route.meta.isClient == false) &&
    //   store.state.isLoggedIn == true &&
    //   store.state.userType == "customer"
    // ) {
    //   next({ path: "/Shopping" });
    //   return;
    // }

    next();
});

export default router;