<template>
  <div id="appNavbar">
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#ffffff">
      <v-layout wrap justify-center>
        <v-flex xs12 v-for="(item, i) in appNavItems" :key="i" text-center pa-2 pl-0 text-none align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs12 px-0 pl-2 align-self-center text-left>
              <!-- <router-link :to="item.route"> -->
              <v-icon>{{ item.icon }}</v-icon>

              <a v-if="item.name != 'Account'" :href="item.route">
                <span class="text-uppercase" :style="$route.path == item.route
                    ? {
                      'font-family': 'poppinsbold',
                    }
                    : {
                      'font-family': 'poppinsbold',
                    }
                  " style="
                    font-size: 12px;
                    color: black;
                    letter-spacing: 3px;
                    cursor: pointer;
                    color:#000;
                  ">
                  {{ item.name }}
                </span>
              </a>
              <span v-else class="text-left" style="
                    font-size: 12px;
                    color: black;
                    letter-spacing: 3px;
                    cursor: pointer;
                  ">
                <AccountMenu2 />
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar app dark color="#005f32" dense flat :height="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md'
        ? '70px'
        : '100px'
      " class="hidden-lg-and-up">
      <v-toolbar-title>
        <a href="https://www.wti.org.in/">
          <v-layout wrap justify-start>
            <v-flex xs5 pa-2 sm5 md5 lg10 xl1 align-self-center text-left>
              <a class="routerlink" href="https://www.wti.org.in/">
                <v-img contain height="90px" :src="require('./../../../public/wtilogo.png')"></v-img>
              </a>
            </v-flex>
          </v-layout>
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="mr-8" @click.stop="sideNav = !sideNav">
        <div style="padding: 7px; background-color: #000">
          <v-icon large color="#ffffff">mdi-menu</v-icon>
        </div>
      </v-btn>
    </v-app-bar>
    <!-- <v-app-bar
      color="#FEBD08"
      elevation="0"
      height="70px"
      class="hidden-md-and-down"
    >
      <v-layout wrap justify-center fill-height>
        <v-flex xs2 align-self-center>
          <router-link to="/">
            <span
              style="
                font-family: TitBold;
                font-size: 20px;
                cursor: pointer;
                color: #FFFFFF;
              "
            >
              TIGERTHON
            </span>
          </router-link>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-app-bar> -->
  </div>
</template>
<script>
import AccountMenu2 from "./accountMenu2";
export default {
  components: {
    AccountMenu2,
  },
  data() {
    return {
      sideNav: false,
      // navItems: [

      //   { name: "Rules", route: "/" },
      //   { name: "Resources", route: "/" },
      //   { name: "Project", route: "/" },
      //   { name: "Updates", route: "/" },
      //   { name: "Discussion", route: "/" },
      //   { name: "FAQ", route: "/" },
      // ],
      navItems: [
        // { name: "Home", route: "https://www.jobs.wti.org.in/Shopping" },
        // { name: "Research", route: "" },
        // { name: "Catalouge", route: "https://www.jobs.wti.org.in/Shopping" },
        // {
        //   name: "My orders",
        //   route: "https://www.jobs.wti.org.in/Shopping/orders",
        // },
        // { name: "My Cart", route: "https://www.jobs.wti.org.in/productCart" },
        { name: "Account", route: "" },
      ],
      dropdown: [
        {
          title: "Publications",
        },
        {
          title: "Projects",
        },
      ],
      drop: [
        {
          title: "Volunteer",
          route: "/Volunteer",
        },
        {
          title: "Donate",
          route: "/About",
        },
        {
          title: "Membership",
          route: "/membership",
        },
      ],
    };
  },
  computed: {
    appNavItems() {
      return this.navItems;
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: TitR !important;
}

input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: TitR !important;
}

.searchBox .v-input__control {
  min-height: 10px !important;
}

.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>
